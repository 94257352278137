<template>
  <div class="page">
    <LandingHeader />
    <div  style="flex: 1 0 auto;"></div>
    <LandingFooter />
  </div>
</template>

<script>
export default {
  name: "LandingUseCases",
  components: {
    LandingHeader:() => import("@/components/Landing/LandingHeader"),
    LandingFooter:() => import("@/components/Landing/LandingFooter")
  },
  mounted() {
    document.title = 'Use cases';
  }
}
</script>

<style lang="scss">
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 40px;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

span,
time,
label {
  display: inline-block;
}

a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
  }
}

img,
svg {
  max-width: 100%;
  height: auto;
}

button,
input,
optgroup,
select,
textarea {
  line-height: 1.5;
}

button {
  cursor: pointer;
}
@import "@/scss/landing/style.scss";
#app,.landing{
  height:100%;
}
</style>
